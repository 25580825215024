<template>
  <b-container class="mb-7">
    <h1>System Info</h1>
    <div class="row support-item">
      <div class="header col-sm-2">System</div>
      <div class="header col-sm-3">Version</div>
      <div class="header col-sm-2">Status</div>
    </div>
    <div class="row support-item">
      <div class="col-sm-2">Dashboard</div>
      <div class="col-sm-3">{{ dashboardVersion }}</div>
      <div class="col-sm-2"><span class="fas fa-check-circle" /></div>
    </div>
    <div class="row support-item">
      <div class="col-sm-2">API</div>
      <div class="col-sm-3">{{ apiVersion }}</div>
      <div class="col-sm-2" v-if="apiStatus"><span class="fas fa-check-circle" /></div>
      <div class="col-sm-2" v-else><span class="fas fa-exclamation-circle" /></div>
    </div>
  </b-container>
</template>

<script>
import config from '@/config';
import axios from '@/axios';
import { featureEnabled } from '@/lib/launch-darkly';

export default {
  data: function () {
    return {
      dashboardVersion: config.VERSION,
      apiStatus: null,
      apiVersion: null
    };
  },
  metaInfo: {
    title: 'System Information'
  },
  async created() {
    const showSystemInfo = await featureEnabled('view-system-info');

    if (!showSystemInfo) {
      this.$router.push('/');
    }
    axios
      .get('/status')
      .then((response) => {
        const status = response.data.data;
        this.apiStatus = status.status;
        this.apiVersion = status.gitCommit;
      })
      .catch((error) => {
        this.apiStatus = null;
        this.apiVersion = 'error';
        this.errorMessage = error;
      });
  }
};
</script>

<style scoped>
.support-item {
  margin-top: 1rem;
  font-size: 1.25rem;
  padding-top: 1rem;
  border-top: 1px solid #eeeeee;
}
.fa-check-circle {
  color: #5db966;
}
.fa-exclamation-circle {
  color: red;
}
.header {
  font-weight: bold;
}
</style>
